import { FC, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import styles from './TagOutroBlock.module.scss';

export interface Props {
    tagOutro: string;
}

const replaceNewLine = (str: string) => (str?.length > 0 ? str.replace(/\\n+/g, '\n') : '');

const MAX_ALLOWED_STRING_LENGTH = 500;

export const TagOutroBlock: FC<Props> = ({ tagOutro }) => {
    const __common = useTranslation('common').t;
    const length = tagOutro.length;
    const [isLoadMore, setActive] = useState<boolean>(false);
    const handleToggle = () => {
        setActive(!isLoadMore);
    };

    return (
        <footer id="tag-outro" className={styles['TagOutro']}>
            {tagOutro ? (
                <div
                    className={isLoadMore ? styles['load-more'] : styles['default']}
                    dangerouslySetInnerHTML={{
                        __html: replaceNewLine(tagOutro),
                    }}
                />
            ) : null}
            {length >= MAX_ALLOWED_STRING_LENGTH ? (
                <div className={isLoadMore ? styles['is-shown'] : styles['background']}>
                    <button className={isLoadMore ? styles['is-shown'] : ''} onClick={handleToggle}>
                        {__common`load-more`}
                    </button>
                </div>
            ) : null}
        </footer>
    );
};
