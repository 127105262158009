import { Route } from './Route';

export const ParentByTagRoute: Partial<Record<Route, Route>> = {
    [Route.Brand]: Route.BrandOverview,
    [Route.Car]: Route.CarOverview,
    [Route.Circuit]: Route.CircuitOverview,
    [Route.Club]: Route.ClubOverview,
    [Route.Competition]: Route.CompetitionOverview,
    [Route.Dossier]: Route.DossierOverview,
    [Route.Driver]: Route.DriverOverview,
    [Route.Grandsprix]: Route.F1Calendar,
    // [Route.Grandsprix]: Route.GrandsprixOverview,
    [Route.Location]: Route.LocationOverview,
    [Route.Organization]: Route.OrganizationOverview,
    [Route.Person]: Route.PersonOverview,
    [Route.Player]: Route.PlayerOverview,
    [Route.RaceClass]: Route.RaceClassOverview,
    [Route.TeamBoss]: Route.TeamBossOverview,
    [Route.Team]: Route.TeamOverview,
    [Route.Topic]: Route.TopicOverview,
};
